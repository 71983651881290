<template>
  <div
    v-expose="{ id: '1-22-2-25', data: { activity_from: 'goods'} }"
    class="follow"
  >
    <div class="follow__title">
      <div class="follow__title_left">
        <span
          class="header-title"
          role="heading"
          tabindex="0"
        >{{ languageText?.SHEIN_KEY_PC_25389 }}</span>
        <div 
          v-if="isShowLabel"
          class="follow__label"
        >
          {{ labelText }}
        </div>
      </div>
      
      <span
        v-enterkey
        v-tap="{ id: '1-22-2-24', data: { activity_from: 'goods'} }"
        class="more"
        role="link"
        tabindex="0"
        @click="jumpPage('more')"
      >{{ followListsFormate.length ? languageText?.SHEIN_KEY_PC_15663 : `0 ${languageText?.SHEIN_KEY_PC_29598}` }}
        <sui_icon_more_right_12px
          size="12px"
          :is-rotate="GB_cssRight"
          class="mt__2"
        />
      </span>
    </div>
    <div
      v-if="followListsFormate.length"
      class="follow__container"
    >
      <div class="follow-lists">
        <swiper-container
          ref="followSwiperRef"
          init="false"
          pagination="true"
          :dir="GB_cssRight ? 'rtl' : 'ltr'"
          class="follow-lists__swiper-container"
        >
          <template
            v-for="(follow, index) in followListsFormate"
            :key="index"
          >
            <swiper-slide
              v-for="item in follow"
              :key="item && item.products"
              v-expose="analysisData('1-22-2-29', item)"
              v-tap="analysisData('1-22-2-30', item)"
              class="follow-lists__swiper-slide good"
              @click="jumpPage('detail',item?.product)"
            >
              <div class="good__img-wrap">
                <img
                  :class="{lazyload: !!index, good__img}"
                  :src="!!index ? IMG_LINK?.LAZY_IMG : cutImg(item?.product?.goods_img)"
                  :data-src="cutImg(item?.product?.goods_img)"
                  :style="{ width: '100%', height: 'auto' }"
                  alt=""
                />
                <div class="good__title">
                  {{ item.title }}
                </div>
              </div>
              <!-- 价格本期隐藏，误删 -->
              <!-- <div class="good__price">
                  <ProductCardPriceSimple 
                    :goods-info="item.product"
                    :language="languageText"
                    :config="{
                      hidePriceBottomLabel: true,
                      noCamelCasePrice: true,
                    }"
                  />
                </div> -->
            </swiper-slide>
          </template>
        </swiper-container>
        <template v-if="followListsFormate.length > 1">
          <div 
            ref="prevRef"
            class="swiper-prev"
          >
            <Icon
              size="16px"
              :is-rotate="GB_cssRight"
              name="sui_icon_more_left_12px_1"
            />
          </div>
          <div 
            ref="nextRef"
            class="swiper-next"
          >
            <Icon
              color="#222"
              :is-rotate="GB_cssRight"
              size="16px"
              name="sui_icon_more_right_12px_1"
            />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Pagination, Navigation } from 'swiper/modules'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
import { ref, reactive, nextTick, onMounted, computed, inject } from 'vue'
import { getGoodsUrl } from 'public/src/pages/common/utils/index.js'
import schttp from 'public/src/services/schttp'
// import ProductCardPriceSimple from 'public/src/pages/components/productItemV3/components/ProductCardPrice/ProductCardPriceSimple.vue'
import { Icon, sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'

const props = defineProps({
  followData: Object,
  newArrivalRes: Object,
})

const userInfo = inject('userInfo')
const abtInfo = inject('abtInfo')
const languageText = inject('languageText')
const apolloConfig = inject('apolloConfig')
const commonInfo = inject('commonInfo')
const cutImg = inject('cutImg')
const { SiteUID, IMG_LINK, GB_cssRight, PUBLIC_CDN = '' } = commonInfo

const isSsr = Boolean(props.followData && Object.keys(props.followData).length)

// 是否有促销
let hasPromotion = false
if (abtInfo?.storepromo?.param?.storepromo != 'old') {
  hasPromotion = props.newArrivalRes?.data?.hasPromotion == 1
}
// 是否有上新
const hasRefresh = props.newArrivalRes?.data?.hasRefresh == 1

// 接口返回followLists内容
const followLists = reactive(props.followData?.formateStoreAndGoods || [])
// const followListsFormate = reactive([])
const memberId = ref(userInfo?.member_id || '')
const new_checkHistory = ref(false)
const promo_checkHistory = ref(false)

const jumpPage = (type, product = {}) => {
  if(type == 'more') {
    if(isShowLabel.value) {
      if(!promo_checkHistory.value && hasPromotion) {
        window.localStorage.setItem(`CLICK_PROMO_BUTTON_TIME_${memberId.value}`, new Date().getTime())
      } else if (!new_checkHistory.value && hasRefresh) {
        window.localStorage.setItem(`CLICK_NEW_BUTTON_TIME_${memberId.value}`, new Date().getTime())
      }
    }
    let url = '/store/following'
    if(followLists.length) {
      url += handleMoreParams()
    }
    window.location.href = url
  } else {
    const url = getGoodsUrl(product.goods_url_name, product.goods_id, product.cat_id)
    window.location.href = url
  }
}

// followlist页面传递adp置顶
const handleMoreParams = () => {
  // adp 最多传递七个出去
  const storeCodes = followLists.map(item => item.storeCode)
  const uniqueStoreCodes = new Set(storeCodes)
  const hasSameStoreCode = uniqueStoreCodes.size !== storeCodes.length
  const params = {}
  if(hasSameStoreCode) {
    const key = followLists[0]['storeCode']
    const adps = []
    followLists.map((item, index) => {
      if(index < 7) {
        adps.push(item.product.goods_id)
      }
    })
    const value = adps.join(',')
    params[key] = value
  } else {
    followLists.map((item) => {
      const key = item['storeCode']
      const value = item.product.goods_id
      params[key] = value
    })
  }
  return `?adps=${encodeURIComponent(JSON.stringify(params))}`
}

const initData = async () => {
  const params = { 
    page_num: 1,
    page_size: 10,
    new_arrival: 0, // 默认值
    abt: abtInfo,
    hasPromotion: hasPromotion ? 1 : 0,
    is_promotion: 0, // 默认值
  }
  // 获取关注店铺列表
  const result = await schttp({
    method: 'POST',
    url: '/api/user/store_following/followList/query',
    data: params
  })
  if(result.code != -1 ) {
    const { formateStoreAndGoods } = result
    followLists.push(...formateStoreAndGoods)
  }
}

const followSwiperRef = ref()
const prevRef = ref()
const nextRef = ref()
const initSwiper = () => {
  nextTick(() => {
    if (followListsFormate.value.length) {
      const swiperEl = followSwiperRef.value
      if (!swiperEl) return
  
      const options = {
        injectStylesUrls: [
          `${PUBLIC_CDN}/she_dist/libs/swiper/modules/navigation-element.min.css`,
          `${PUBLIC_CDN}/she_dist/libs/swiper/modules/pagination-element.min.css`,
        ],
        injectStyles: [
          `
          .swiper-pagination {
            --swiper-pagination-bottom: 2px;
          }
          .swiper-pagination-bullet {
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: #ccc;
          }
          .swiper-pagination-bullet-active {
            background: #222;
          }
          `
        ],
        modules: [Navigation, Pagination],
        navigation: {
          enabled: followListsFormate.value.length > 1,
          prevEl: prevRef.value,
          nextEl: nextRef.value
        },
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 5,
        uniqueNavElements: false,
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
      }
      Object.assign(swiperEl, options)
      swiperEl.initialize()
    }
  })
}

// 计算时间相隔是否在三天内
const isWithinThreeDays = (pre, now) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  const differenceInDays = Math.abs((now - pre) / millisecondsPerDay)
  return differenceInDays <= 3
}

// 检查是否三天内点击过【new】
const checkedHasClickedDot = ()=> {
  const pre1 = window.localStorage.getItem(`CLICK_NEW_BUTTON_TIME_${memberId.value}`)
  const pre2 = window.localStorage.getItem(`CLICK_PROMO_BUTTON_TIME_${memberId.value}`)
  const now = new Date().getTime()
  if (pre1) {
    new_checkHistory.value = isWithinThreeDays(pre1, now)
  }
  if (pre2) {
    promo_checkHistory.value = isWithinThreeDays(pre2, now)
  }
}

// 格式化处理后的followLists内容
const followListsFormate = computed(() => {
  return [
    [...followLists.slice(0, 3)],
    [...followLists.slice(3, 6)],
    [...followLists.slice(6, 9)],
  ].filter((follow) => follow.length)
})

// 是否展示标签
const isShowLabel = computed(() => {
  if(!!apolloConfig?.shein_suggested_sale_price[SiteUID]) {
    return false
  }
  return (!new_checkHistory.value && hasRefresh) || (!promo_checkHistory.value && hasPromotion)
})
// 标签内容
const labelText = computed(() => {
  let str = ''
  if(!promo_checkHistory.value && hasPromotion) {
    str = languageText?.SHEIN_KEY_PC_29604
  } else if(!new_checkHistory.value && hasRefresh) {
    str = languageText?.SHEIN_KEY_PC_29605
  }
  return str
})
// 上报埋点
const analysisData = (id, item) => {
  return {
    id: id,
    data: {
      shop_code: item?.store_code,
      goods_id: item?.product?.goods_id,
      shop_tag: (labelText.value == languageText?.SHEIN_KEY_PC_29604) ? 'promotion' : (labelText.value == languageText?.SHEIN_KEY_PC_29605) ? 'new' : '',
    }
  }
}

onMounted(async () => {
  if(!isSsr) {
    await initData()
  }
  checkedHasClickedDot()
  initSwiper()
})

</script>

<style lang="less" scoped>
.follow {
  margin-top: 10px;
  background: #fff;
  &__title_left {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }
  &__label {
    display: flex;
    align-items: center;
    margin-left: 6px;
    background: #FF5454;
    padding: 1px 2px;
    color: #FFF;
    font-size: 9px;
  }
  &__container {
    padding: 0 10px 4px;
  }
}
.follow-lists {
  position: relative;
  padding: 0 26px;
  cursor: pointer;
  &__swiper-container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    height: 110px;
  }
  &__swiper-slide {
    flex-shrink: 0;
    margin-right: 5px;
  }
  .swiper-button-disabled {
    opacity: 0.35;
  }
  .swiper-next,
  .swiper-prev {
    position: absolute;
    top: calc(50% - 10px);
    display: flex;
    align-items: center;
    display: none;
  }
  &:hover {
    .swiper-next,
    .swiper-prev {
      display: flex;
    }
  }
  .swiper-next {
    .right(0);
  }
  .swiper-prev {
    .left(0);
  }
}
.good {
  display: flex;
  flex-direction: column;
  align-items: center;
  &:nth-child(2) {
    margin: 0 5px;
  }
  &__img-wrap {
    width: 68px;
    height: 90px;
    cursor: pointer;
    position: relative;
  }
  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 12px;
    background: #FF5454;
    color: #FFF;
    font-size: 9px;
    text-align: center;
  }
  &__img {
    width: 100%;
    height: 100%;
  }
  &__title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0 2px;
    height: 16px;
    color: #FFF;
    text-align: center;
    font-size: 11px;
    background: rgba(0, 0, 0, 0.60);
    line-height: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__price {
    margin-top: 4px;
    color: var(---sui_color_gray_dark1, #222);
    font-family: "SF UI Text";
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
