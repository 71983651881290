import schttp from 'public/src/services/schttp'
const CACHE = {
  bills: {},
}
const { SiteUID, appLanguage } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

function queryOrderDetail(billno) {
  // valid
  return new Promise((resolve) => {
    if (CACHE.bills[billno] === undefined) {
      schttp({
        url: '/api/orders/base/get_order_info/get',
        params: {
          billno: billno,
        },
        schttp: { needLogin: true },
      })
        .then((res) => {
          if (res.code == 0 && res.info && res.info.order) {
            CACHE.bills[billno] = res.info.order
            resolve(res.info.order)
          } else {
            resolve({})
          }
        })
        .catch((err) => {
          console.log(err)
          resolve({})
        })
    } else {
      resolve(CACHE.bills[billno])
    }
  })
}

// 获取b2b2c文案
function queryOrderStatusText() {
  let storageKey = 'ORDER_STATUS_TEXT'
  let textKey = `${SiteUID}_${appLanguage}_key`
  let allSiteCacheData = null
  let curSiteCacheData = {
    textInfo: [],
    currentTime: 0,
  }
  try {
    allSiteCacheData = JSON.parse(localStorage.getItem(storageKey))
    curSiteCacheData = {
      ...curSiteCacheData,
      textInfo: allSiteCacheData?.[textKey] || [],
      currentTime: allSiteCacheData?.currentTime || 0,
    }
  } catch (err) {
    curSiteCacheData = {
      textInfo: [],
      currentTime: 0,
    }
  }
  return new Promise((resolve, reject) => {
    let { currentTime = 0, textInfo = [] } = curSiteCacheData
    let expired = currentTime + 2 * 60 * 60 * 1000 < new Date().getTime()
    if (textInfo?.length && !expired) {
      resolve(textInfo)
    } else {
      schttp({
        url: '/api/orders/base/getOrderStatusText/get',
      })
        .then((res) => {
          if (res?.code == '1000') {
            let result = allSiteCacheData || {}
            if (!expired) {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || [],
              }
            } else {
              result = {
                ...result,
                [textKey]: res?.data?.orderStatusNoticeDetails || [],
                currentTime: new Date().getTime(),
              }
            }
            localStorage.setItem('ORDER_STATUS_TEXT', JSON.stringify(result))
            resolve(result?.[textKey] || [])
          } else {
            resolve([])
          }
        })
        .catch((err) => {
          console.log(err)
          reject([])
        })
    }
  })
}

function queryCodFrontOrderInfo (billno) {
  return new Promise((resolve) => {
    schttp({
      url: '/api/orders/base/queryCodFrontOrderInfo/query',
      params: { billno },
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch((err) => {
        console.log(err)
        resolve({})
      })
  })
}

function submitCodOrderAuditResultsInfo (params = {}) {
  return new Promise((resolve) => {
    schttp({
      method: 'POST',
      url: '/api/orders/base/submitCodOrderAuditResults/update',
      data: params,
      schttp: { needLogin: true }
    })
      .then(res => {
        resolve(res || {})
      })
      .catch((err) => {
        console.log(err)
        resolve({})
      })
  })
}

export const getOrderDoublePoints = async (billno) => {
  const orderInfo = await queryOrderDetail(billno)
  return orderInfo
}

export const getOrderStatusText = async () => {
  let textInfo = await queryOrderStatusText()
  return textInfo
}

export const transformPercentList = (percentList) => {
  if (!percentList?.length) return percentList
  let list = JSON.parse(JSON.stringify(percentList))
  list.map(item => {
    item.day = item?.day || item?.detail_time || ''
    item.percent = item.percent || item?.detail_time_percentage || ''
    return item
  })
  return list
}

export const queryCodFrontOrderInfoFun = async billno => {
  const orderInfo = await queryCodFrontOrderInfo(billno)
  return orderInfo
}

export const submitCodOrderAuditResultsFun = async params => {
  const orderInfo = await submitCodOrderAuditResultsInfo(params)
  return orderInfo
}

export const getRefundReasonListEvt = async params => {
  let data = await schttp({
    url: `/api/orders/base/getRefundReasonList/get`,
    params: params,
  })
  return data?.info?.reasonLanguages || []
}

/*cod订单短信验证非ar站disabled按钮*/
export const getCodSites = function (siteId) {
  const siteInfo = {
    ar: [
      'ar',
      'mar',
      'pwar',
      'pwaren',
      'iosshsa',
      'iosshkw',
      'iosshae',
      'iosshqa',
      'iosshom',
      'iosshbh',
      'andshsa',
      'andshkw',
      'andshae',
      'andshqa',
      'andshom',
      'andshbh',
    ],
    my: ['my', 'mmy', 'ioshmy', 'andshmy'],
    tw: ['tw', 'mtw', 'iosshtw', 'andshtw'],
    in: ['in', 'min', 'pwin', 'iosshin', 'andshin', 'rwin', 'rwmin'],
    ph: ['ph', 'mph', 'iosshph', 'andshph'],
    vn: ['vn', 'mvn', 'iosshvn', 'andshvn'],
    ma: ['ma', 'pwma', 'iosshma', 'andshma'],
    id: ['id', 'mid', 'iosshid', 'andshid'],
    th: ['th', 'mth', 'iosshth', 'andshth'],

    rwar: ['rwar', 'rwmar', 'iosrwar', 'andrwar'],
  }
  //m站处理
  siteInfo['mar'] = siteInfo['pwar'] = siteInfo['pwaren'] = siteInfo['ar']
  siteInfo['mmy'] = siteInfo['my']
  siteInfo['mtw'] = siteInfo['tw']
  siteInfo['min'] = siteInfo['pwin'] = siteInfo['in']
  siteInfo['mph'] = siteInfo['ph']
  siteInfo['mvn'] = siteInfo['vn']
  siteInfo['pwma'] = siteInfo['ma']
  siteInfo['mid'] = siteInfo['id']
  siteInfo['mth'] = siteInfo['th']
  siteInfo['rwmar'] = siteInfo['rwar']

  const filterId = siteInfo[SiteUID || siteId] || []
  return filterId
}
