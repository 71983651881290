const orderBenefitStrMap = {
  1: 'promotion_end', // 活动已经结束
  2: 'coupon_expire', // 券过期
  3: 'promotion_ending', // 活动即将结束
  5: 'price_protection' // 价保
}

const goodsBenefitStrMap = {
  1: 'lowest', // 历史新低
  2: 'best_seller', // 榜单
  3: 'x_sold' // x件已售
}

export const getScene = (orderItem, abtInfo, inFloor) => {
  if (inFloor) return 'new_floor'
  const popType = abtInfo?.MeOrderUnpaid?.p?.PopType
  if (!orderItem || !popType) return
  const { total_discount_new } = orderItem
  const discountType = +(total_discount_new?.amount) ? '1' : '2'
  return (popType + discountType).toLowerCase()
}

export const getBenefitType = (orderItem, abtInfo) => {
  if (!orderItem) return
  const { orderGoodsList, orderBenefitTempt } = orderItem
  if (!orderBenefitTempt) return
  let orderBenefitStr = orderBenefitStrMap[orderBenefitTempt.kind] || '-'
  if (orderBenefitTempt.kind === 4 && orderBenefitTempt.data.coupons?.length) {
    orderBenefitStr = 'return_coupon' // 返券
  } else if (orderBenefitTempt.kind === 4 && orderBenefitTempt.data.point) {
    orderBenefitStr = 'return_points' // 返积分
  }
  const popType = abtInfo?.MeOrderUnpaid?.p?.PopType
  return orderGoodsList
    ?.map((n, i) =>
      [
        i + 1, // 报告坑位
        orderBenefitStr, // 订单利益点
        popType === 'A'
          ? ''
          : goodsBenefitStrMap[n.productBenefitTempt?.kind] || '-', // 商品利益点
        n.product.stock >= 50 ? 'in_stock' : 'sold_out' // 库存情况
      ]
        .filter(Boolean)
        .join('`')
    )
    .join(',')
}
