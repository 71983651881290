<template>
  <!-- 仅存在超省卡时展示 -->
  <div
    v-if="!isShowPrime"
    class="extra-pannel fsp-element"
    @click="jumpTo"
  >
    <div class="extra-pannel__left">
      <div class="extra-pannel__logo-wrap">
        <img
          class="logo"
          :src="extraLogo"
        />
        <div
          v-if="infoData?.expiredFlag"
          class="status"
        >
          {{ language?.SHEIN_KEY_PC_24495 }}
        </div>
      </div>

      <div class="extra-pannel__content">
        <span v-html="infoData.contentText"></span>
      </div>
    </div>

    <div
      class="extra-pannel__right"
      :class="{'extra-pannel__right-ar':GB_cssRight}"
    >
      <div class="extra-pannel__right-content">
        <div class="extra-pannel__btn">
          <div
            v-if="infoData.limitedFlag"
            class="extra-pannel__btn_limited"
          >
            {{ language?.SHEIN_KEY_PC_27002 }}
          </div>
          <span v-html="infoData.btnText"></span>
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
          />
        </div>
        <div
          class="extra-pannel__coupon"
          v-html="infoData.couponText"
        ></div>
      </div>
    </div>
  </div>

  <!-- 与shein club并存时展示 -->
  <div
    v-if="isShowPrime"
    class="extra-pannel2 fsp-element"
    :class="{'extra-pannel2__ar':GB_cssRight}"
    @click="jumpTo"
  >
    <div class="extra-pannel2__logo-wrap">
      <img
        class="logo"
        :src="extraLogo"
      />
      <div
        v-if="infoData?.expiredFlag"
        class="status"
      >
        {{ language?.SHEIN_KEY_PC_24495 }}
      </div>
    </div>
    <div class="extra-pannel2__content">
      <span v-html="infoData.contentText"></span>
    </div>

    <div class="extra-pannel2__footer">
      <div
        v-if="!infoData.couponText"
        class="extra-pannel2__btn-line"
      >
        <div
          v-if="infoData.limitedFlag"
          class="extra-pannel2__btn_limited"
        >
          {{ language?.SHEIN_KEY_PC_27002 }}
        </div>
        <span v-html="infoData.btnText"></span>
        <Icon
          name="sui_icon_more_right_14px"
          :is-rotate="GB_cssRight"
        />
      </div>

      <template v-if="infoData.couponText">
        <div
          class="extra-pannel2__tip"
          v-html="infoData.couponText"
        ></div>
        <div class="extra-pannel2__btn">
          <div
            v-if="infoData.limitedFlag"
            class="extra-pannel2__btn_limited"
          >
            {{ language?.SHEIN_KEY_PC_27002 }}
          </div>
          <span v-html="infoData.btnText"></span>
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { Icon } from '@shein-aidc/icon-vue3'

export default {
  name: 'ExtraPannel',
  inject: ['commonInfo'],
  components: {
    Icon
  },
  props: {
    isShowPrime: {
      type: Boolean,
      default: false,
    },
    isOpenAbt: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => {},
    },
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      extraLogo: '//img.ltwebstatic.com/images3_ccc/2024/05/30/6d/1717050373e7b069ad7584978fe1376df79f951475.webp',
      GB_cssRight: this.commonInfo?.GB_cssRight || false,
      saver_status: '', // "valid“生效中 "not_effective"已过期 "null"未开通未获取到值
      hasUnpaidOrder: 0, // 0 没有待支付订单 1 有待支付订单
      infoData: {},
    }
  },
  watch: {
    info: {
      handler() {
        this.infoData = this.getInfoData()
      },
      immediate: true,
    }
  },
  methods: {
    template,
    jumpTo() {
      daEventCenter.triggerNotice({
        daId: '1-22-2-1002',
        extraData: {
          activity_from: 'me',
          is_club_show: null,
          saver_status: this.saver_status,
          has_unpaid_order: this.hasUnpaidOrder
        }
      })
      let query = 'productsource=me'
      location.href = `${gbCommonInfo?.langPath}/user/sheinsaver?${query}`
    },
    getStrongRed(text) {
      return `<strong class="strong-red">${text}</strong>`
    },
    getStrongBlack(text) {
      return `<strong class="strong-black" style="margin-left: 6px;">${text}</strong>`
    },
    getAlignCenter(text, fontSizeClass) {
      return `<span class="flex-center ${fontSizeClass}">${text}</span>`
    },
    handleMemberInfo(buy_status) {
      this.saver_status = 'valid'
      try { // 防止为空的情况，故try catch
        const { SHEIN_KEY_PC_27026, SHEIN_KEY_PC_27028, SHEIN_KEY_PC_27010, SHEIN_KEY_PC_27029, SHEIN_KEY_PC_27020 } = this.language
        const { paid_detail } = this.info
        const { billno_enjoy_privilege_info } = paid_detail
        const { price_usd, req_currency_price_with_symbol = '', discount_price_with_symbol_usd = '' } = billno_enjoy_privilege_info
        const right_config_list = paid_detail?.product_info?.right_config_list || []
        let availableCoupons = 0 //可用优惠券
        right_config_list.some(el => {
          if(el.right_cycle_status == 2) {
            availableCoupons = el.coupon_available_amount
            return true
          }
        })
        const flag1 = price_usd > 0 // 是否有省额
        const flag2 = availableCoupons > 0 // 可用优惠券是否大于0
        const savedText = this.getStrongBlack(req_currency_price_with_symbol || discount_price_with_symbol_usd)  //带货币省额文案
        const availableCouponsText = this.getStrongRed(availableCoupons + 'X')
        let obj = {
          btnText: SHEIN_KEY_PC_27020,
          contentText: ''
        }
        switch(true) {
          case flag1 && flag2: {
            const text1 = this.template(savedText, SHEIN_KEY_PC_27026)
            const text2 = this.template(availableCouponsText, SHEIN_KEY_PC_27028)
            let fontSizeClass = ''
            if(SHEIN_KEY_PC_27026.length > 22 && this.isShowExtraAndPrime) {
              fontSizeClass = 'ft13'
            }
            obj.contentText = this.getAlignCenter(text1, fontSizeClass)
            obj.couponText = text2
            break
          }
          case flag1 && !flag2: {
            const savedText = this.getStrongBlack(req_currency_price_with_symbol || discount_price_with_symbol_usd)
            obj.contentText = this.template(savedText, SHEIN_KEY_PC_27010)
            break
          }
          case !flag1 && flag2: {
            obj.contentText = this.template(availableCouponsText, SHEIN_KEY_PC_27028)
            break
          }
          case !flag1 && !flag2: {
            obj.contentText = SHEIN_KEY_PC_27029
            break
          }
        }

        // 未支付引导
        if(buy_status == 3 && this.hasUnpaidOrder) {
          obj.btnText = this.language?.SHEIN_KEY_PC_28785
        }

        return obj
      } catch (error) {
        console.log(error)
      }
    },
    handleNonmemberInfo() {
      this.saver_status = 'null'
      try {
        const { SHEIN_KEY_PC_27007, SHEIN_KEY_PC_27022, SHEIN_KEY_PC_27023 } = this.language
        let obj = {
          btnText: SHEIN_KEY_PC_27007,
          contentText: ''
        }
        const product_info_list = this.info?.product_info_list || []
        const filterItem = product_info_list.filter(el => el.is_recommend == 1)
        let recommendItem // 推荐包，优先取 状态is_recommend为1，否则取第一个
        if(filterItem.length || product_info_list.length) {
          recommendItem = filterItem?.[0] || product_info_list[0]
        }
        const total_discount_price_with_symbol = recommendItem?.product_price_info?.total_discount_price_with_symbol || 0 //优惠金额文案
        const price_discount_rate = recommendItem?.product_price_info?.price_discount_rate || 0 //优惠金额

        const num = recommendItem?.right_config_list?.reduce((total, item) => total += item.coupon_num, 0)
        const total = recommendItem?.equivalent_value_with_symbol
        const numText = this.getStrongRed(num + 'X')
        const totalText = this.getStrongRed(total)
        const totalValueText = this.template(numText, totalText, SHEIN_KEY_PC_27023) //产品价值

        if(price_discount_rate > 0) {
          const text = this.getStrongRed(total_discount_price_with_symbol)
          obj.contentText = totalValueText
          obj.couponText = this.template(text, SHEIN_KEY_PC_27022)
          obj.limitedFlag = true
        } else {
          obj.contentText = totalValueText
        }
        return obj
      } catch (error) {
        console.log(error)
      }
    },
    handleExpiredMembersInfo() {
      this.saver_status = 'not_effective'
      daEventCenter.triggerNotice({
        daId: '1-22-2-1000',
        extraData: {
          is_buyable_status: 2
        }
      })
      try {
        const { SHEIN_KEY_PC_27014, SHEIN_KEY_PC_27026, SHEIN_KEY_PC_27024, SHEIN_KEY_PC_27025, SHEIN_KEY_PC_27027 } = this.language
        let obj = {
          btnText: SHEIN_KEY_PC_27014,
          contentText: '',
          expiredFlag: true
        }
        const { paid_detail } = this.info
        const { discount_price_usd, discount_price_with_symbol, discount_price_with_symbol_usd } = paid_detail?.discount_summary_info || {}
        const product_info_list = this.info?.product_info_list || []
        const filterItem = product_info_list.filter(el => el.is_recommend == 1)
        let recommendItem // 推荐包，优先取 状态is_recommend为1，否则取第一个
        if(filterItem.length || product_info_list.length) {
          recommendItem = filterItem?.[0] || product_info_list[0]
        }
        const total_discount_price_with_symbol = recommendItem?.product_price_info?.total_discount_price_with_symbol || 0 //优惠金额
        const savedText = this.getStrongBlack(discount_price_with_symbol || discount_price_with_symbol_usd) // 历史省额
        const totalDiscountPriceText = this.getStrongRed(total_discount_price_with_symbol) //优惠金额
        const couponNum = recommendItem?.right_config_list?.reduce((total, item) => total += item.coupon_num, 0)
        const couponNumText = this.getStrongRed(couponNum + 'X') // 优惠券（张）

        const flag1 = discount_price_usd > 0 // 是否有历史省额
        const flag2 = recommendItem?.product_price_info?.price_discount_rate > 0 // 是否有优惠
        switch(true) {
          case flag1 && flag2: {
            const text1 = this.template(savedText, SHEIN_KEY_PC_27026)
            const text2 = this.template(totalDiscountPriceText, SHEIN_KEY_PC_27027)
            obj.contentText = text1
            obj.couponText = text2
            obj.limitedFlag = true
            break
          }
          case flag1 && !flag2: {
            const text1 = this.template(savedText, SHEIN_KEY_PC_27026)
            const text2 = this.template(couponNumText, SHEIN_KEY_PC_27025)
            obj.contentText = text1
            obj.couponText = text2
            break
          }
          case !flag1 && flag2: {
            obj.contentText = this.template(totalDiscountPriceText, SHEIN_KEY_PC_27024)
            obj.limitedFlag = true
            break
          }
          case !flag1 && !flag2: {
            obj.contentText = this.template(couponNumText, SHEIN_KEY_PC_27025)
            break
          }
        }
        return obj
      } catch (error) {
        console.log(error)
      }
    },
    genRenewPanelInfo() {
      const { SHEIN_KEY_PC_27014, SHEIN_KEY_PC_28181, SHEIN_KEY_PC_28182, SHEIN_KEY_PC_28183, SHEIN_KEY_PC_28184, SHEIN_KEY_PC_28185 } = this.language
      const { paid_detail, product_info_list = [] } = this.info
      const { billno_enjoy_privilege_info } = paid_detail
      const { req_currency_price_with_symbol, req_currency_price,  discount_price_with_symbol_usd } = billno_enjoy_privilege_info
      const filterItem = product_info_list.filter(el => el.is_recommend == 1)
      let recommendItem // 推荐包，优先取 状态is_recommend为1，否则取第一个
      if(filterItem.length || product_info_list.length) {
        recommendItem = filterItem?.[0] || product_info_list[0]
      }
      const hasSaving = req_currency_price > 0 // 是否有省额
      const hasDiscount = recommendItem?.product_price_info?.price_discount_rate > 0 // 是否有优惠

      let obj = {
        btnText: SHEIN_KEY_PC_27014,
        contentText: '',
        couponText: SHEIN_KEY_PC_28185,
      }

      const reqPrice = req_currency_price_with_symbol || discount_price_with_symbol_usd // 兜底文案
      const couponNum =  recommendItem?.right_config_list?.reduce((total, item) => total += item.coupon_num, 0)
      if(hasSaving) {
        if(hasDiscount) {
          obj.contentText = this.template(this.getStrongRed(reqPrice), this.getStrongRed(recommendItem?.product_price_info?.total_discount_price_with_symbol),  SHEIN_KEY_PC_28181)
          obj.limitedFlag = true
        } else {
          obj.contentText = this.template(this.getStrongRed(reqPrice), this.getStrongRed(couponNum), SHEIN_KEY_PC_28182)
        }
      } else {
        if(hasDiscount) {
          obj.contentText = this.template(this.getStrongRed(recommendItem?.product_price_info?.total_discount_price_with_symbol), SHEIN_KEY_PC_28184)
          obj.limitedFlag = true
        } else {
          obj.contentText = this.template(this.getStrongRed(couponNum), this.getStrongRed(recommendItem?.equivalent_value_with_symbol), SHEIN_KEY_PC_28183)
        }
      }
      return obj
    },
    getInfoData() {
      let initPanel = {
        btnText: '', // 按钮文案
        expiredFlag: false, // 过期标识
        limitedFlag: false, // 优惠标识
        contentText: '', // 内容区域文案
        couponText: '', // 优惠区域文案
        tipText: '', // 提示区域文案
      }
      const { isOpenAbt, info, language } = this
      const { buy_status, paid_detail, reason } = info || {}
      this.hasUnpaidOrder = [4, 5].includes(reason) ? 1 : 0
      const flag1 = paid_detail?.product_info?.front_status === 1 // 正式会员情况
      const flag2 = !flag1 && buy_status == 3 && reason != 3 && isOpenAbt // 非会员中间状态情况
      const flag3 = !flag1 && buy_status == 1 && isOpenAbt // 非会员情况
      const flag4 = !flag1 && buy_status == 2 && isOpenAbt // 过期会员情况
      let result

      if(flag1) {
        if(buy_status == 2) {
          // 满足续费
          result = this.genRenewPanelInfo()
        } else {
          result = this.handleMemberInfo(buy_status) 
        }
      } else if (flag2 || flag3) {
        if(flag3) {
          daEventCenter.triggerNotice({
            daId: '1-22-2-1000',
            extraData: {
              is_buyable_status: 1
            }
          })
        }
        result = this.handleNonmemberInfo()
        // 未支付引导
        if(buy_status == 3 && this.hasUnpaidOrder) {
          result.btnText = language?.SHEIN_KEY_PC_28785
        }
      } else if (flag4) {
        result = this.handleExpiredMembersInfo()
      }
      daEventCenter.triggerNotice({
        daId: '1-22-2-1001',
        extraData: {
          activity_from: 'me',
          is_club_show: null,
          saver_status: this.saver_status,
          has_unpaid_order: this.hasUnpaidOrder
        }
      })
      return {
        ...initPanel,
        ...result
      }
    },
  },
}
</script>

<style lang="less" scoped>
:deep(.strong-red) {
  color: #F82854;
  font-family: SF UI Display;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}
:deep(.strong-black) {
  color: var(--saver-dark-blue, #240B44);
  text-align: center;
  font-family: Arial;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
:deep(.flex-center) {
  display: flex;
  align-items: center;
}
:deep(.ft13) {
  font-size: 13px;
}
.extra-pannel {
  margin-top: 28px;
  display: flex;
  min-height: 120px;
  border-radius: 6px;
  border: 1px solid rgba(255, 202, 214, 0.80);
  background: linear-gradient(270deg, #FFEDF1 0%, #FCF2FF 100%);
  cursor: pointer;
  &__left {
    width: 350px;
    position: relative;
  }
  &__logo-wrap {
    display: flex;
    align-items: center;
    padding: 0 10px;
    height: 34px;
    width: fit-content;
    border-radius: 6px 0px 16px 0px;
    background: linear-gradient(89deg, rgba(255, 43, 119, 0.08) 25.90%, rgba(204, 49, 198, 0.08) 68.09%, rgba(252, 86, 255, 0.08) 100%);
    .logo {
      width: 130px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      border: 1px solid #B6BCDF;
      background: #ECF1FD;
      color: #5D6492;
    }
  }
  &__content {
    margin: 0 0 12px 12px;
    min-height: 74px;
    padding: 4px 0px;
    display: flex;
    align-items: center;
    color: #240B44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
  &__right {
    flex: 1;
    position: relative;
    padding: 16px 0;
    &::before{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 206px;
      height: 56px;
      background: url('/she_dist/images/extra-bg-0ba468084c.png') no-repeat;
      background-size: contain;
    }
  }
  &__right-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 24px 0;
    border-left: 1px dashed rgba(248, 40, 84, 0.35);
    min-height: 88px;
  }
  &__right-ar::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 206px;
    height: 56px;
    background: url('/she_dist/images/extra-ar-bg-4f6a5c15fe.png') no-repeat;
    background-size: contain;
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 144px;
    min-height: 32px;
    gap: 2px;
    padding: 6px;
    color: #F82854;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 81px;
    border: 1px solid var(--saver-red, #F82854);
  }
  &__btn_limited{
    position: absolute;
    right: 0;
    top: -27px;
    display: inline-flex;
    padding: 2px 4px;
    justify-content: flex-end;
    align-items: center;
    color: #FFF;
    text-align: right;
    font-family: SF UI Display;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 2px;
    background: linear-gradient(90deg, #F93776 0%, #D52BFF 100%);
    &::after{
      content: "";
      position: absolute;
      bottom: -4px;
      right: 14px;
      width: 6px;
      height: 4px;
      background: url('/she_dist/images/extra-tip-80ad474b78.png') no-repeat;
      background-size: contain;
    }
  }
  &__coupon {
    margin-top: 8px;
    text-align: center;
    color: #240B44;
  }
}
.extra-pannel2 {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 12px;
  width: 274px;
  min-height: 165px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid rgba(255, 202, 214, 0.80);
  background: linear-gradient(270deg, #FFEDF1 0%, #FCF2FF 100%);
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    width: 72px;
    height: 56px;
    background: url('/she_dist/images/extra2-bg-d366509531.png') no-repeat;
    background-size: contain;
  }
  &__ar::after{
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    width: 72px;
    height: 56px;
    background: url('/she_dist/images/extra2-ar-bg-11399613bc.png') no-repeat;
    background-size: contain;
  }
  &__content {
    box-sizing: border-box;
    min-height: 64px;
    flex: 1;
    margin: 4px 12px;
    padding: 8px 0;
    display: flex;
    align-items: center;
    color: #240B44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  &__logo-wrap {
    display: inline-flex;
    height: 34px;
    padding: 0px 10px;
    align-items: center;
    flex-shrink: 0;
    width: fit-content;
    border-radius: 6px 0px 16px 0px;
    background: linear-gradient(89deg, rgba(255, 43, 119, 0.08) 25.90%, rgba(204, 49, 198, 0.08) 68.09%, rgba(252, 86, 255, 0.08) 100%);
    
    .logo{
      width: 98px;
    }
    .status {
      display: flex;
      padding: 2px 6px;
      margin-left: 6px;
      align-items: center;
      border-radius: 28px;
      border: 1px solid #B6BCDF;
      background: #ECF1FD;
      color: #5D6492;
    }
  }
  &__footer {
    padding: 4px 12px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: rgba(249, 55, 118, 0.08);
  }
  &__tip {
    color: #240B44;
    font-family: SF UI Display;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    flex: 1;
    margin-right: 4px;
  }
  &__btn {
    display: flex;
    position: relative;
    box-sizing: border-box;
    padding: 6px;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 80px;
    border: 1px solid #240B44;
    color: #240B44;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  &__btn-line {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 28px;
    padding: 6px;
    border-radius: 80px;
    border: 1px solid #240B44;
    color: #240B44;
    text-align: center;
    font-family: Arial;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  &__btn_limited {
    position: absolute;
    right: 0;
    top: -27px;
    width: max-content;
    display: inline-flex;
    padding: 2px 4px;
    justify-content: flex-end;
    align-items: center;
    color: #FFF;
    font-family: SF UI Display;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    border-radius: 2px;
    background: linear-gradient(90deg, #F93776 0%, #D52BFF 100%);
    &::after{
      content: "";
      position: absolute;
      bottom: -4px;
      right: 14px;
      width: 6px;
      height: 4px;
      background: url('/she_dist/images/extra-tip-80ad474b78.png') no-repeat;
      background-size: contain;
    }
  }
}
</style>
