<template>
  <div
    v-expose="{id: '1-22-2-31'}"
    class="news-pannel"
  >
    <div class="news-pannel__header">
      <div class="news-pannel__header-left">
        <Icon
          size="20px"
          name="sui_icon_me_news_20px"
          color="#337EFF"
        />
        <div class="news-pannel__title">
          {{ language.SHEIN_KEY_PC_30636 }}
        </div>
      </div>
      <div
        v-tap="{ id: '1-22-2-32', data: { button_type: 'more'} }"
        class="news-pannel__header-right"
        role="link"
        tabindex="0"
        @click="jumpTo('more')"
      >
        {{ language.SHEIN_KEY_PC_15663 }}
        <Icon
          size="12px"
          :is-rotate="context.GB_cssRight"
          class="mt__2"
          name="sui_icon_more_right_12px"
          color="#666"
        />
      </div>
    </div>
    <div class="news-pannel__content">
      <swiper-container
        ref="swiperRef" 
        class="swiper-container"
        init="false"
        destroy-on-disconnected="false"
      >
        <swiper-slide
          v-for="(item, index) in newsList"
          :key="index"
          v-tap="analysisData('1-22-2-32', item)"
          class="swiper-container__item"
          @click="jumpTo('detail', item)"
        >
          <div
            class="swiper-container__item-text"
            v-html="item.formateTitle"
          ></div>
        </swiper-slide>
      </swiper-container>
    </div>
  </div>
</template>

<script setup>
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
import { ref, onMounted, computed } from 'vue'
import { 
  Icon,
} from '@shein-aidc/icon-vue3'
import { CccLink } from 'public/src/pages/components/ccc/common/ccc-link.js'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  newsInfo: {
    type: Object,
    default: () => {}
  },
  context: {
    type: Object,
    default: () => {}
  }
})

typeof window !== 'undefined' && register()

const newsList = computed(() => {
  const list = props.newsInfo.newsEntranceList || []
  list.forEach(item => {
    item.formateTitle = item?.articleTitle?.replace(/#(.*?)#/g, '<span class="highlight">$1</span>')
  })
  return list
})

const analysisData = (id, item = {}) => {
  let content_list = '-'
  if(item.hrefType && item.hrefTarget) {
    content_list = `${item.hrefType}_${item.hrefTarget}`
  }
  return {
    id: id,
    data: {
      button_type: 'content',
      content_list,
    }
  }
}

const activeIndex = ref()
const swiperRef = ref(null)
const cccLink = ref()

const initSwiper = () => {
  const options = {
    direction: 'vertical',
    loop: true,
    initialSlide: 0,
    modules: [Autoplay],
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    },
    unAutoInit: true,
    on: {
      slideChangeTransitionEnd: (event) => {
        activeIndex.value = event?.realIndex
      }
    }
  }
  Object.assign(swiperRef.value, options)
  swiperRef.value.initialize()
}

const initCCCLink = () => {
  cccLink.value = new CccLink({
    sceneData: {
      pageFrom: 'me',
    },
  })
}

const jumpTo = (type, item) => {
  let url = ''
  if(type == 'more') {
    url = `${props.context.langPath}/user/news?page_from=me`
  } else {
    url = cccLink.value.getFullLink({
      item
    })
  }
  window.open(url, '_blank')
}


onMounted(async () => {
  initSwiper()
  initCCCLink()
})

</script>

<style lang="less" scoped>
.news-pannel {
  width: 286px;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 3px;
  background: rgba(217, 234, 255, 0.50);
  margin-bottom: 10px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__header-left {
    display: flex;
    align-items: center;
  }
  &__title{
    color: #337EFF;
    font-size: 16px;
    font-weight: 700;
    margin-left: 4px;
  }
  &__header-right{
    color: #666;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  &__content {
    margin-top: 8px;
    color: #222;
    font-size: 14px;
    cursor: pointer;
    height: 16px;
    overflow: hidden;
  }
}
.swiper-container {
  height: 16px;
  &__item-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /deep/ .highlight {
      color: #FA6338;
    }
  }
}
.mt__2 {
  margin-top: -2px;
}
</style>
