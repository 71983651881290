const indexAnalysis = {
  clickCoupons(activity_param) {
    sa('send', { activity_name: 'click_my_coupons', activity_param }, { beacon: 1 })
  },
  clickGiftcardMiddle() {
    sa('send', { activity_name: 'click_gift_card' }, { beacon: 1 })
  },
  clickMyMessageRight() {
    sa('send', { activity_name: 'click_my_message_right' }, { beacon: 1 })
  },
  clickOrderDetails() {
    sa('send', { activity_name: 'click_order_details' }, { beacon: 1 })
  },
  clickPointsMiddle(activity_param) {
    sa('send', { activity_name: 'click_shein_points', activity_param }, { beacon: 1 })
  },
  clickProcessingMiddle() {
    sa('send', { activity_name: 'click_processing_middle' }, { beacon: 1 })
  },
  clickRecentlyViewedGoodsRight() {
    sa('send', { activity_name: 'click_recently_viewed_goods_right' }, { beacon: 1 })
  },
  clickRecentlyViewedRight() {
    sa('send', { activity_name: 'click_recently_viewed_right' }, { beacon: 1 })
  },
  clickReturnsMiddle() {
    sa('send', { activity_name: 'click_returns_middle' }, { beacon: 1 })
  },
  clickReviewMiddle() {
    sa('send', { activity_name: 'click_review_middle' }, { beacon: 1 })
  },
  clickProfileImageMiddle() {
    sa('send', { activity_name: 'click_self_profile_image_middle' }, { beacon: 1 })
  },
  clickServiceRecords() {
    sa('send', { activity_name: 'click_service_records_right' }, { beacon: 1 })
  },
  clickShippedMiddle() {
    sa('send', { activity_name: 'click_shipped_middle' }, { beacon: 1 })
  },
  clickUnpaidMiddle() {
    sa('send', { activity_name: 'click_unpaid_middle' }, { beacon: 1 })
  },
  clickViewAllMiddle() {
    sa('send', { activity_name: 'click_view_all_middle' }, { beacon: 1 })
  },
  clickVipMiddle() {
    sa('send', { activity_name: 'click_vip_middle' }, { beacon: 1 })
  },
  clickSheinClub () {
    daEventCenter.triggerNotice({
      daId: '1-22-2-23'
    })
  },
  clickWalletMiddle(activity_param) {
    sa('send', { activity_name: 'click_my_wallet', activity_param }, { beacon: 1 })
  },
  clickWishlistGoods() {
    sa('send', { activity_name: 'click_wishlist_goods_right' }, { beacon: 1 })
  },
  clickWishlistMore() {
    sa('send', { activity_name: 'click_wishlist_view_all' }, { beacon: 1 })
  },
}

const mapAnalysis = {
  'coupon': 'clickCoupons',
  'giftCard': 'clickGiftcardMiddle',
  'mssage': 'clickMyMessageRight',
  orderDetail: 'clickOrderDetails',
  'points': 'clickPointsMiddle',
  processing: 'clickProcessingMiddle',
  recentView: 'clickRecentlyViewedGoodsRight',
  recentViewMore: 'clickRecentlyViewedRight',
  returns: 'clickReturnsMiddle',
  'review': 'clickReviewMiddle',
  profile: 'clickProfileImageMiddle',
  records: 'clickServiceRecords',
  shipped: 'clickShippedMiddle',
  unpaid: 'clickUnpaidMiddle',
  allOrder: 'clickViewAllMiddle',
  vip: 'clickVipMiddle',
  prime: 'clickSheinClub',
  wallet: 'clickWalletMiddle',
  wishlist: 'clickWishlistGoods',
  wishlistMore: 'clickWishlistMore',
}

function analysisEvent(id, activity_param) {
  if (Object.keys(mapAnalysis).includes(id)) {
    indexAnalysis[mapAnalysis[id]](activity_param)
  }
}

export {
  analysisEvent
}
