import { mapState, mapMutations } from 'vuex'
import { daEventCenter } from 'public/src/services/eventCenter'
import schttp from 'public/src/services/schttp'
import { getUserInfo } from 'public/src/pages/common/userInfo'
import { isLogin } from 'public/src/pages/common/utils/index.js'

const { lang, SiteUID } = gbCommonInfo
daEventCenter.addSubscriber({ modulecode: '1-22-2' })
import { getLocalStorage, setLocalStorage } from '@shein/common-function'


export const pointsOrCouponMixins = {
  data () {
    return {
      pointsOrCouponInfo: {},
      isClickCoupon: 0,
      idClickPoints: 0
    }
  },
  computed: {
    ...mapState(['unReadNum', 'isMsgActivityAndPromoOnly', 'couponOrPointsData']),
    showCouponTips () {
      let { couponNum, isOpenCoupon, isClickCoupon } = this.couponOrPointsData || {}
      let result = isOpenCoupon && !isClickCoupon && couponNum >= 1
      if (result) {
        daEventCenter.triggerNotice({
          daId: '1-22-2-12',
          extraData: {
            reminder_type: 1,
          }
        })
      }
      return result
    },
    showPointsTips () {
      let { pointsNum, isOpenPoints, isClickPoints } = this.couponOrPointsData || {}
      let result = isOpenPoints && !isClickPoints && pointsNum >= 50
      if (result) {
        daEventCenter.triggerNotice({
          daId: '1-22-2-14',
          extraData: {
            reminder_type: 1,
          }
        })
      }
      return result
    }
  },
  methods: {
    ...mapMutations(['changeCouponOrPointsData']),
    async getCouponOrPointsNotice () {
      // 15分钟获取一次接口数据
      const user = await getUserInfo()
      const { member_id = '' } = user || {}
      let couponOrPointsData = this.parseCookieData(getLocalStorage(`${member_id}_${SiteUID}_${lang}_couponOrPointsData`))
      let { isReqData } = couponOrPointsData || {}
      let isClickCoupon = getLocalStorage(`${member_id}_couponHit`) ? true : false
      let isClickPoints = getLocalStorage(`${member_id}_pointsHit`) ? true : false
      if (isReqData) {
        this.changeCouponOrPointsData({ ...couponOrPointsData, isClickCoupon, isClickPoints })
        return false
      }

      let result = await this.getCouponOrPointsDataEvt()
      let expirePointTotal = result && result[0] && result[0]?.point?.expirePointTotal || 0
      let expireCouponTotal = result && result[1] && result[1]?.total || 0
      let pointsOrCouponInfo = { pointsNum: expirePointTotal, couponNum: expireCouponTotal }
      setLocalStorage({
        key: `${member_id}_${SiteUID}_${lang}_couponOrPointsData`,
        value: JSON.stringify({ ...couponOrPointsData, ...pointsOrCouponInfo, isReqData: 1, isClickCoupon, isClickPoints }),
        expire: 60 * 5 * 1000
      })
      this.changeCouponOrPointsData({ ...pointsOrCouponInfo, isReqData: 1, isClickCoupon, isClickPoints })
    },
    async getCouponOrPointsDataEvt () {
      const pointsRequest = async function () {
        const res = await schttp({
          url: '/api/user/points/dueToExpirePoint/get',
          params: {
            expire_day: 7
          }
        })
        return res && res.info || {}
      }
      const couponRequest = async function() {
        const res = await schttp({
          method: 'POST',
          url: '/api/user/coupon/couponList/get',
          data: {
            type: 1,
            category: 0,
            limit: 20,
            page: 1,
            timeStatus: 2
          },
        })
        return res && res.info || {}
      }
      const result = await Promise.all([pointsRequest(), couponRequest()])
      return result
    },
    async pointsOrCouponJumpEvt (id, type) {
      if (['coupon', 'points'].includes(id) && isLogin) {
        // 点击右上角下拉积分优惠券入口
        const user = await getUserInfo()
        const { member_id = '' } = user || {}
        if (id == 'coupon' && this.showCouponTips) {
          setLocalStorage({ key: `${member_id}_couponHit`, value: 1, expire: 3600 * 24 * 3 * 1000 })
          this.changeCouponOrPointsData({ isClickCoupon: true })
          type && daEventCenter.triggerNotice({
            daId: '1-22-2-11',
            extraData: {
              reminder_type: 1,
              operation: type == 1 ? 0 : 1
            }
          })
        } else if (id == 'points' && this.showPointsTips) {
          setLocalStorage({ key: `${member_id}_pointsHit`, value: 1, expire: 3600 * 24 * 7 * 1000 })
          this.changeCouponOrPointsData({ isClickPoints: true })
          type && daEventCenter.triggerNotice({
            daId: '1-22-2-13',
            extraData: {
              reminder_type: 1,
              operation: type == 1 ? 0 : 1
            }
          })
        }
      }
    },
    parseCookieData (data) {
      let tempData = {}
      if (data) {
        try {
          tempData = JSON.parse(data) || {}
        } catch (err) {
          tempData = {}
        }
      }
      return tempData
    },
  }
}
