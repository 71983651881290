<!-- 
  单双列复用，内容join btn
 -->
<template>
  <div class="prime-pannel2-btn">
    <template v-if="hasUnpaidOrder">
      <div
        class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line"
        @click.stop="lintToOrderDetail(hasUnpaidOrder)"
      >
        {{ language.SHEIN_KEY_PC_28747 }}
        <Icon
          name="sui_icon_more_right_14px"
          :is-rotate="GB_cssRight"
          size="14px"
          is-responsive-name
        />
      </div>
    </template>
    <template v-else>
      <template v-if="currentUserPrimeStatus == 1">
        <div class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line">
          <div
            v-if="discountPrice > 0"
            class="prime-pannel2__footer__btn_limited"
          >
            {{ language.SHEIN_KEY_PC_27003 }}
          </div>
          {{ language.SHEIN_KEY_PC_27007 }}
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
            size="14px"
            is-responsive-name
          />
        </div>
      </template>
      <template v-if="currentUserPrimeStatus == 2">
        <div class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line">
          {{ language.SHEIN_KEY_PC_27020 }}
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
            size="14px"
            is-responsive-name
          />
        </div>
      </template>
      <template v-if="currentUserPrimeStatus == 3">
        <div class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line">
          <div
            v-if="discountPrice > 0"
            class="prime-pannel2__footer__btn_limited"
          >
            {{ language.SHEIN_KEY_PC_27003 }}
          </div>
          {{ language.SHEIN_KEY_PC_27014 }}
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
            size="14px"
            is-responsive-name
          />
        </div>
      </template>
      <template v-if="currentUserPrimeStatus == 4">
        <div class="prime-pannel2__footer__btn prime-pannel2__footer__btn_line">
          <div
            v-if="discountPrice > 0"
            class="prime-pannel2__footer__btn_limited"
          >
            {{ language.SHEIN_KEY_PC_27003 }}
          </div>
          <span v-if="!!buyWhen && buyWhen != 4">
            {{ language.SHEIN_KEY_PC_27014 }}
          </span>
          <span v-else>{{ language.SHEIN_KEY_PC_27007 }}</span>
          <Icon
            name="sui_icon_more_right_14px"
            :is-rotate="GB_cssRight"
            size="14px"
            is-responsive-name
          />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { Icon } from '@shein-aidc/icon-vue3'

import { daEventCenter } from 'public/src/services/eventCenter/index'
import { template } from '@shein/common-function'

export default {
  name: 'PrimePannelContent',
  inject: ['commonInfo'],
  components: {
    Icon
  },
  props: {
    /**
     * @param {Number} currentUserPrimeStatus 1. 未购买 / 退订中 2. 生效中-非临期 3. 生效中-临期 4. 逾期/ 已退订
     * @param {Number} discountPrice 是否有购买优惠数额
     * @param {String} buyWhen 购买时机
     * @param {Object} language
     * @param {Number} columnNumber 单双列
     */
    currentUserPrimeStatus: {
      type: Number,
      default: 1,
    },
    discountPrice: {
      type: Number,
      default: 0,
    },
    buyWhen: {
      type: Number,
      default: 0,
    },
    columnNumber: {
      type: Number,
      default: 0,
    },
    language: {
      type: Object,
      default: () => {},
    },
    hasUnpaidOrder: {
      type: String,
      default: '',
    },
    getExposeParams: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    GB_cssRight() {
      return this.commonInfo?.GB_cssRight
    }
  },
  methods: {
    template,
    lintToOrderDetail (orderNo) {
      daEventCenter.triggerNotice({
        daId: '1-22-8-2',
        extraData: this.getExposeParams(),
      })
      location.href = `${gbCommonInfo?.langPath}/user/orders/detail/${encodeURIComponent(
        orderNo
      )}`
    }
  },
}
</script>

<style lang="less" scoped>
/* stylelint-disable */
.prime-pannel2-btn {
  width: 100%;
}
.prime-pannel2 {
  &__footer {
    &__tip {
      color: var(--sui-color-club-rosegold-dark-1, #873c00);
      font-family: SF UI Display;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      flex: 1;
      margin-right: 4px;
      span {
        color: #fa6338;
        font-weight: bold;
      }
      p {
        word-break: break-all;
      }
    }
    &__btn {
      position: relative;
      display: flex;
      padding: 6px;
      justify-content: center;
      align-items: center;
      border-radius: 81px;
      border: 1px solid #873c00;
      color: var(--sui-color-club-rosegold-dark-1, #873c00);
      text-align: center;
      font-family: Arial;
      font-size: 13px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
    }
    &__btn_line {
      width: 100%;
    }
    &__btn_limited {
      position: absolute;
      right: 0;
      top: -27px;
      width: max-content;
      display: inline-flex;
      padding: 2px 4px;
      justify-content: flex-end;
      align-items: center;
      color: #fff;
      font-family: SF UI Display;
      font-size: 12px;
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      border-radius: 2px;
      background: linear-gradient(90deg, #c96e3f 48.82%, #eaa27e 100%);
      &::after {
        content: '';
        position: absolute;
        bottom: -4px;
        right: 14px;
        width: 6px;
        height: 4px;
        background: url('/she_dist/images/prime2-tip-0ff8be5ccc.png') no-repeat;
        background-size: contain;
      }
    }
  }
}
</style>
